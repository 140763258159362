.header
  position: fixed
  width: 100%
  z-index: 999
  background: #fff
  top: 0

//+section-mq()
//  margin-bottom: 0 !important
//  height: 60px
//  position: fixed
//  z-index: 100000
//  transition: all 0.2s
//  top: 0px
//
//  .logo
//    max-width: 180px
//    float: right
//    padding-top: 9px
//
//  &:hover
//    .nav
//      background: rgba(255,255,255,1)
//

.infoheader
  background: c(blue, bg)
  margin-bottom: 0

  +mq($from: phablet, $until: desktop)
    .content
      padding: 0 50px !important

  &-ul
    display: flex
    list-style: none
    justify-content: space-between
    margin: 0
    padding: 20px 0
    align-items: center
    +mq($until: desktop)
      flex-wrap: wrap
      justify-content: space-between
    +mq($until: phablet)
      grid-gap: 15px 30px
      display: grid
      grid-template-columns: 1fr



    li
      +f_bold
      font-size: 24px
      color: c(primary, 500)

      +mq($until: tablet)
        font-size: 18px

      &:last-child
        display: flex
        gap: 1rem

      +mq($until: phablet)
        &:nth-child(2)
          order: 1
          grid-row: 1
          grid-column: 1 / 3
          text-align: center

        &:first-child
          order: 2
          grid-row: 2
          grid-column: 1

        &:last-child
          order: 3
          grid-row: 2
          grid-column: 2 / 3






.nav
  &__level1
    height: auto
    padding: 16px
    border-bottom: 1px solid c(blue, lines)
    margin-bottom: 0

    &__content
      display: flex

      +mq($until: desktop)
        flex-wrap: wrap
        justify-content: space-between


  &-ul
    display: flex
    grid-gap: 32px
    list-style: none
    margin: 0 auto
    cursor: pointer
    align-items: center
    transition: all .2s linear

    +mq($until: desktop)
      align-items: flex-start
      flex-direction: column
      margin: 0
      max-height: 0
      overflow: hidden
      padding-top: 0
      flex-basis: 100%
      margin-left: 105px

      @at-root.nav__level1__content.is-active &
        max-height: 500px
        padding-top: 35px

    li, a
      font-size: 16px
      display: inline-flex
      align-items: center

      &.icon
        grid-gap: 5px

      svg
        overflow: visible

      &:hover,
      &:active
        // color: c(blue, light)

        svg
          path
    // fill: c(blue, light)


    li
      &.js-button--mag
        &.open
          color: c(primary)
          //border-bottom: 1px solid c(primary) !important

          svg
            transform: rotate(180deg)
            transition: .3s ease all

      svg
        transition: .3s ease all


  &__level2
    display: none
    //border-top: 1px solid c(blue, lines)
    background-color: #fff
    position: absolute
    top: 145px
    left: 0
    z-index: 1
    width: 100%

    +mq($until: desktop)
      //padding-bottom: 0
      padding-top: 0

    &__close
      background-color: transparent
      border: 0
      cursor: pointer
      display: block
      height: 48px
      margin-left: auto
      margin-right: 1rem
      outline: 0
      position: relative
      width: 48px

      +mq($from: desktop)
        display: none

      &::after,
      &::before
        background-color: c(black)
        content: ''
        height: 3px
        position: absolute
        width: 1.5rem

      &::after
        transform: rotate(45deg)

      &::before
        transform: rotate(-45deg)

li.js-button--mag.open
  .nav__level2
    display: block

//  z-index: 100
//  position: relative
//  background: rgba(255,255,255,1)
//
//
//  &__wrapper
//    +content-mq()
//    padding-top: 1rem
//    height: 4rem
//
//  &__level1
//    max-height: 60px
//    cursor: pointer
//    background: c(white)
//    position: relative
//    top: 0px
//    z-index: 1
//    transition: top 0.4s ease-in-out
//    border-bottom: 1px solid #dedede
//
//
//    // +mq($until: desktop)
//    //   top: -15rem
//
//    &.active
//      top: 0
//
//
//    +mq($until: tablet)
//      ul
//        background: white
//        margin-top: -5px
//        padding-top: 20px !important
//
//
//    li
//      &.active
//        .nav__level2
//          display: block !important
//
//
//
//
//    &__content
//      +content-mq()
//      padding-left: 4rem !important
//      padding-right: 4rem !important
//      +span(12)
//      text-align: left
//
//      +mq($until: tablet)
//        padding-left: 0px
//        background: white
//
//
//      +mq($until: desktop)
//        padding-left: 30px !important
//        padding-right: 0px !important
//
//      & > ul
//        list-style-type: none
//        li
//          a
//            letter-spacing: 0.0225em
//        & > li
//          display: inline-block
//          padding: 0rem 2rem
//          margin: 15px 0px
//          border-right: 1px solid #000
//          +u-font(16, 20)
//          color: c(black)
//          transition: all .2s
//          letter-spacing: 0.0225em
//
//          &:hover
//            color: c(primary)
//
//          &:first-child
//            padding: 0em 2rem 0rem 0rem
//            +mq($until: desktop)
//              padding-left: 0rem
//
//          &:last-child
//            border: 0px
//            a
//              font-weight: normal !important
//
//          &:after
//            content: ''
//
//          +mq($until: tablet)
//            display: block
//            padding: 0.7rem 2rem
//
//          .active &.active
//            // background: c(primary, 500)
//            color: c(primary)
//            a
//              color: c(white)
//
//            +mq($until: tablet)
//              height: 100%
//
//            .nav__level2
//              display: block
//
//          .no-touch .active &:hover
//            &, a
//              color: c(primary)
//
//          & > a
//            +u-font(16, 17)
//            font-weight: normal
//            color: c(black)
//
//            &:hover
//              color: c(primary)
//
//          &.active > a
//            color: c(white)
//
//
//  &__level2
//    background-color: c(white)
//    border-bottom: 1px solid #dedede
//    position: absolute
//    top: 3rem
//    left: 0
//    z-index: 1
//    width: 100%
//    display: none
//    height: 620px
//
//    &__content
//      +mq($until: desktop)
//        li
//          width: 20rem
//
//    strong
//      display: block
//      +u-font(20,24)
//
//
//    +mq($until: tablet)
//      position: relative
//      top: 0rem
//      margin-top: 0.625rem
//      box-shadow: none
//
//      strong
//        display: block
//        padding-bottom: 5px
//      a
//        font-size: 14px !important
//
//
//    &__content
//      +content-mq()
//      padding-left: 4rem !important
//      padding-right: 4rem !important
//      text-align: left
//      width: 100%
//      +mq($until: desktop)
//        padding-left: 5.5rem
//
//      +mq($until: phablet)
//        padding-left: 0px !important
//        padding-right: 0px !important
//
//
//      & > ul
//        list-style-type: none
//        & > li
//          display: block
//          +mq($until: tablet)
//            display: block
//          a
//            +u-font(16, 21)
//            color: c(white)
//            display: block
//
//    &--report
//      position: relative
//      width: 100%
//      margin-top: 15px
//      overflow: hidden
//      min-height: 530px
//
//      li
//        padding: 0
//        transition: all .2s
//        border-bottom: 1px solid c(black)
//        width: 48%
//        a
//          padding: 15px 0
//          +mq($until: phablet)
//            padding: 9px 0
//        strong,a
//          font-size: 17px
//          color: c(black)
//          font-weight: normal
//          display: block
//
//          strong
//            position: relative
//            overflow: hidden
//
//          svg
//            width: 7px
//            position: absolute
//            right: -50px
//            top: 6px
//            transition: all 0.2s ease-in-out
//
//        &:hover
//          border-bottom: 1px solid c(primary)
//          a,strong
//            color: c(primary)
//          span, img
//            opacity: 1
//            display: block
//
//          strong
//            svg
//              right: 6px
//
//        +mq($until: tablet)
//          min-height: 0
//          padding: 0rem
//          margin-bottom: 2rem
//
//        img
//          margin: 1rem 0
//          opacity: 0
//          display: none
//          transition: all 0.2s ease-in-out
//
//        span
//          opacity: 0
//          display: none
//
//        .no-touch &:hover
//          background: c(white)
//
//          a
//            color: c(primary, 500) !important
//
//      &__right
//        position: absolute
//        right: 0px
//        top: 0px
//        width: 50%
//        padding: 0
//        margin: 0
//
//        span
//          font-size: 17px
//          width: 100%
//          letter-spacing: 0.015em
//          // +f-bold
//          color: c(black)
//          position: absolute
//          max-height: 100%
//          padding: 20px
//          background-color: rgba(255,255,255,.8)
//          bottom: 15px
//
//.header__magazine__trigger
//  display: none
//
//// mobile
//
//+mq($until: tablet)
//
//  .nav__level2
//    height: auto
//  .nav__level1__content
//    height: 55px
//    overflow: hidden
//    ul
//      padding: 15px
//      height: 0%
//      transition: all 0.3s ease-in-out
//      li
//        border-right: 0px
//        a
//          color: black
//
//    &:first-child
//      ul
//        li
//          padding: 0px
//          color: black
//        &:last-child
//          padding-bottom: 15px
//
//  .nav__level2__content
//    padding: 0px
//  .nav__level2--report__right
//    display: none
//  .nav__level2--report
//    li
//      width: 100%
//      margin-bottom: 12px
//      +mq($until: phablet)
//        margin-bottom: 0
//
//  .header__magazine__trigger
//    display: inline-block
//    height: 20px
//    width: 8px
//    margin-left: 10px
//    transform: rotate(90deg)
//    transition: all 0.3s ease-in-out
//    svg
//      path
//        fill: c(black)
//
.mobile-trigger
  display: block
  font-size: 16px
  padding: 35px 0 0 2rem
    left: 2rem
  overflow: hidden
  //height: 50px
  //padding-left: 0px
  &__button
    transition: all 0.3s ease-in-out
    position: relative
    top: 0px
    cursor: pointer

    span
      display: block
      line-height: 1.5rem

      &:first-child
        font-size: 16px
        margin-top: -5px
        +f-bold
        letter-spacing: 0.015em

      &:hover
        color: c(primary)

  +mq($from: desktop)
    display: none
//
//
//  .nav__level1__content
//    &.is-active
//      height: 100%
//      ul
//        height: 100%
//        padding: 15px
//
//        +mq($until: phablet)
//          padding: 0
//          padding-right: 15px
//
//      .mobile-trigger__button
//        top: -45px
//
//  #content-top
//    height: 20px
//
//  .active
//    .header__magazine__trigger
//      transform: rotate(270deg)
//
#top-link
  width: 30px
  position: fixed
  z-index: 20
  bottom: 75px
  right: 40px
  opacity: 0
  transition: opacity 1s ease-in-out

  &.active
    opacity: 1

//#privacy-settings
//  position: fixed
//  z-index: 20
//  bottom: 15px
//  right: 34px
//  width: 40px
//
//
header
  .magazin, .magazine,
  .story01, .story02, .story03, .story04, .story05, .story06, .story07, .story08, .story09, .story01_en, .story02_en, .story03_en, .story04_en, .story05_en, .story06_en, .story07_en, .story08_en, .story09_en
    li
      &.magazine
        color: c(blue)

        svg path
          fill: c(blue)

header
  .vorwort
    li:nth-child(2)
      a
        box-sizing: border-box
        border-color: c(primary) !important
        color: c(primary)

  .downloads,
  .downloads_en
    li:last-child
      a
        color: c(primary)
        border-color: c(primary) !important
        box-sizing: border-box

header
  .nav-ul
    > li
      color: c(primary)
      box-sizing: border-box
      transition: all .2s linear

      +mq($until: desktop)
        width: 100%
        justify-content: flex-end

      a:not(.nav__tile)
        color: c(primary)
        border-bottom: 1px solid transparent

        &:hover, &:active
          border-color: c(primary)
          color: c(primary)

    .home
      padding-bottom: 6px


    &.story01, &.story01_en
      .nav__tiles
        .nav__tile.story01, .nav__tile.story0_en
          background: c(blue, lines)
          color: c(black)

    &.story02, &.story02_en
      .nav__tiles
        .nav__tile.story02, .nav__tile.story02_en
          background: c(blue, lines)
          color: c(black)


    &.story03, &.story03_en
      .nav__tiles
        .nav__tile.story03, .nav__tile.story03_en
          background: c(blue, lines)
          color: c(black)


    &.story04, &.story04_en
      .nav__tiles
        .nav__tile.story04, .nav__tile.story04_en
          background: c(blue, lines)
          color: c(black)


    &.story05, &.story05_en
      .nav__tiles
        .nav__tile.story05, .nav__tile.story05_en
          background: c(blue, lines)
          color: c(black)


    &.story06, &.story06_en
      .nav__tiles
        .nav__tile.story06, .nav__tile.story06_en
          background: c(blue, lines)
          color: c(black)

    &.story07, &.story07_en
      .nav__tiles
        .nav__tile.story07, .nav__tile.story07_en
          background: c(blue, lines)
          color: c(black)


    &.story08, &.story08_en
      .nav__tiles
        .nav__tile.story08, .nav__tile.story08_en
          background: c(blue, lines)
          color: c(black)


    &.story09, &.story09_en
      .nav__tiles
        .nav__tile.story09, .nav__tile.story09_en
          background: c(blue, lines)
          color: c(black)


#content-top
  margin-bottom: 165px

//.header__graphic
//  svg
//    width: 100%
//    height: auto
