// GRID SYSTEM – BASIC GRID MIXINS //

// section mixin
=section($section-spacing: false)
  box-sizing: border-box
  display: block
  width: 100%

  @if $section-spacing
    margin-bottom: $section-spacing

  $debug: false !default
  @if ($debug)
    background-color: rgba(blue, .1)
    +debug('section', blue)

// content mixin
=content($grid-gutter: false, $max-width: false)
  box-sizing: border-box
  display: block
  margin: 0 auto
  font-size: 0
  width: 100%

  @if $max-width
    max-width: $max-width
  @else
    max-width: 100%

  @if $grid-gutter
    padding-left: $grid-gutter/2
    padding-right: $grid-gutter/2

  $debug: false !default
  @if ($debug)
    background-color: rgba(red, .1)
    +debug('content', red)

// column mixin
=col($grid-gutter: false)
  box-sizing: border-box
  display: inline-block
  vertical-align: top

  @if $grid-gutter
    padding-left: $grid-gutter/2
    padding-right: $grid-gutter/2

  $debug: false !default
  @if ($debug)
    background-color: rgba(green, .28)
    border: 1px solid rgba(grey, .4)
    +debug('col', green)

// column span mixin
$columns: 12 !default

=span($n: $columns, $of: $columns)
  width: 100% / $of * $n

// collumn offset mixins
=lead($n: $columns, $of: $columns)
  margin-left: 100% / $of * $n
=trail($n: $columns, $of: $columns)
  margin-right: 100% / $of * $n


// debug info
=debug($text: '', $color: blue)
  &::after
    content: $text
    font-family: monospace
    font-size: 10px
    line-height: 10px
    font-weight: bold
    text-transform: uppercase
    color: rgba($color, .6)
    overflow: hidden
    display: block
