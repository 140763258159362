table, %table
    width: 100% !important
    padding-bottom: 15px
    padding-top: 5px
    line-height: 26px
    font-size: 18px
    border-collapse: separate
    border-spacing: 8px 0

    td
        color: c(black)
        vertical-align: bottom
        padding: 0px 6px 7px 6px

    th
        color: c(black)
        vertical-align: bottom
        padding: 0px 6px 3px 6px
        margin-bottom: 5px

    tbody
        td,
        th
            text-align: right
            vertical-align: bottom

            &:first-child
                text-align: left
                padding-top: 10px

            &.no-after
                &:after
                    display: none

            &.active
                border-left: 2px solid c(primary, 500)
                border-right: 2px solid c(primary, 500)
                //border-top: 2px solid c(primary, 500)
                padding: 12px 8px 8px 8px
                border-bottom: 0
                +f_bold
                color: c(primary)

                &:after
                    height: 1px
                    width: calc(100% - 8px)
                    position: absolute
                    left: 4px
                    bottom: 0px
                    content: ""

            &.active--first
                border-top: 2px solid c(primary, 500)

            &.active--last
                border-bottom: 2px solid c(primary, 500)


        .padding-top
            padding-top: 40px


    thead

        tr
            &:last-child
                th
                    &:after
                        height: 2px

        th, td
            color: #000
            vertical-align: bottom
            text-align: center
            +f_bold
            border-bottom: 1px solid c(black)

            &.a-left
                text-align: left

            &.a-right
                text-align: right

            &.a-center
                text-align: center

            &.a-top
                vertical-align: top

            &.active
                border-left: 2px solid c(primary, 500)
                border-right: 2px solid c(primary, 500)
                border-top: 2px solid c(primary, 500)
                padding: 12px 8px 8px 8px
                letter-spacing: 0.04em
                border-bottom: 0

                &:after
                    height: 1px
                    width: calc(100% - 8px)
                    position: absolute
                    left: 4px
                    bottom: 0px
                    content: ""

            &.active--first
                border-top: 2px solid c(primary, 500)

            &.no-after
                &:after
                    display: none

        td,
        th
            position: relative

            &:after
                //content: ''
                background: #000
                height: 1px
                width: calc(100% - 8px)
                position: absolute
                left: 4px
                bottom: 0px

    tbody

        tr
            td, th
                transition: background 0.5s ease-in-out

            &:hover
                td, th
                    background: c(blue, lines)
                    transition: background 0.5s ease-in-out

                //.active
                //    background: c(primary)
                //    transition: background 0.5s ease-in-out
                //    color: c(white)

                .spacer
                    background: #fff

            &.empty
                td:not(.active)
                    border-bottom: 0

        th, td
            position: relative
            //font-size: 14px

            &.a-left
                text-align: left

            &.a-right
                text-align: right

            &.a-center
                text-align: center

            &.a-top
                vertical-align: top

            strong
                +f_bold

        td
            line-height: 1.5

        tr,
        th,
        &:hover
            //background: c(blue, lines)

            &.empty
                background: none !important

            &.indented
                th
                    padding-left: 15px

            &.double-indented
                th
                    padding-left: 30px
                    font-weight: normal

            &.triple-indented
                th
                    padding-left: 45px
                    font-weight: normal

        td,
        th
            border-bottom: 1px solid c(black)
            position: relative

            &.empty
                border-bottom: 0

            &:after
                //content: ''
                background: #000
                height: 1px
                width: calc(100% - 8px)
                position: absolute
                left: 4px
                bottom: 0px

        tr.active
            th,
            td
                +f_bold

                &:after
                    height: 2px

        tr.semibold
            th,
            td
                +f_bold

        tr.border-btm
            th,
            td
                &:after
                    height: 3px

.bordered
    td, th
        &:after
            height: 2px

    &--light
        td, th
            &:after
                height: 2px
//background: #ccc

.semiactive
    td, th
        +f_bold


.footnote
    color: c(grey, 500)

.no-border
    border-bottom: 0px

    &:after
        background: none


.table__wrapper
    margin-bottom: 15px

    +mq($until: tablet)
        position: relative
        overflow-x: scroll
        background: radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 100% 0
        background-color: white
        background-repeat: no-repeat
        background-size: 10px 100%
        margin-bottom: 15px
        & > table
            background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)), linear-gradient(to left, white 30%, rgba(255, 255, 255, 0)) 100% 0
            background-size: 50px 100%
            background-repeat: no-repeat
            max-width: none
            margin-bottom: 0

.table__title
    color: c(primary)
    +u-font(20, 26)