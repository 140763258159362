.interactive-graphic
  position: relative

  +mq($until: tablet)
    text-align: center

  #pulse > g
    cursor: pointer
    position: relative

  svg
    +mq($until: tablet)
      max-height: 55vh
      width: auto
      margin: 0 auto

  &__infoboxes
    +mq($until: tablet)
      position: relative

  &__infobox
    position: absolute
    opacity: 0
    visibility: hidden
    background-color: white
    padding: 1rem
    text-align: left

    +mq($from: tablet)
      +box-shadow
      top: 0
      left: 75%
      width: 50%

    +mq($until: tablet)
      //background-color: c(blue, light)
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5)
      position: fixed
      top: 105%
      left: 0
      right: 0
      max-width: 80%
      margin: 0 auto


  &__no
    font-size: 1.5rem
    color: c(primary, 500)

    +mq($until: tablet)
      font-size: 2rem
      margin-top: 0
      margin-bottom: 1rem


#lng-terminal
  + .divider
    +mq($until: tablet)
      margin-bottom: 13rem
