.content-grid
  display: grid
  gap: 1rem
  // grid-template-columns: repeat(4, 1fr)
  // grid-template-columns: repeat(4, minmax(min(min(100%, 1fr)), 500px))
  // grid-template-rows: repeat(5, 1fr)
  grid-template-areas: "one" "two" "three" "four" "five" "six" "seven" "eight" "nine"

  +mq($from: tablet)
    grid-template-areas: "one two" "one two" "four five" "four six" "three three" "seven eight" "nine nine"
  +mq($from: desktop)
    grid-template-areas: "one one three three" "two two three three" "two two seven nine" "four five eight nine" "four six eight nine"

  .grid-image
    display: grid
    max-width: 400px
    margin-inline: auto

  .anchor-link
    display: flex
    align-items: center
    gap: .75rem
    .icon--anchor
      width: 30px

  .img--small
    max-width: 200px
    margin-bottom: 3rem
    // padding-bottom: 2rem

  .grid-item
    // display: grid
    // gap: .25rem
    padding: 2rem
    h2, p, li, a
      color: c(white) !important

  #expertenwissen
    grid-area: one
    background-color: c(secondary)
  #gestiegen
    grid-area: two
    background-color: #708EC0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
  #maerkte
    grid-area: three
    background-color: c(purple)
  #fe
    grid-area: four
    background-color: #708EC0
  #geschichte
    grid-area: five
    background-color: c(purple)
  #weltweit
    grid-area: six
    background-color: c(grey, dark)
  #qualitaet
    grid-area: seven
    background-color: c(grey, dark)
  #servicezentren
    grid-area: eight
    background-color: #708EC0
  #naehe
    grid-area: nine
    background-color: c(secondary)


.grid-col-span-2
  grid-column: span 2

.grid-row-span-2
  grid-row: span 2

.grid-row-span-3
  grid-row: span 3


