$palettes: (
  white: (
    500: #fff
  ),
  black: (
    500: #000
  ),
  grey: (
    body: #1D1D1B,
    lines: #8E8E8D,
    500: #61615F,
    dark: #575756,
  ),
  primary: (
    500: #00569D,
    hover: #4D89BA
  ),
  secondary: (
    500: #EB5B25,
    hover: #F18C66,
  ),
  blue: (
    light: #B3CCE2,
    lines: #E0EBF3,
    bg: #F2F6F8,
  ),
  purple: (
    500: #6e414d,
  ),
  tiles: (
    dark: #1D3E68,
    mid: #366CA8,
    light: #5CA2CE,
    turq: #8EC8D2,
    sky: #B4D0DC,
    gold: #C5AA40
  ),
  stories: (
    blue: #1F3A5F,
    green: #C1DBC4
  )


);

@function c-tone-exists-check($palette, $tone) {
  @if map-has-key($palettes, $palette) == false {
    @warn ($palette, $tone);
    @return false;
  }

  @if map-has-key(map-get($palettes, $palette), $tone) == false {
    @warn ($palette, $tone);
    @return false;
  }

  @return true;
}

@mixin c($palette, $tone: 500) {
  @if c-tone-exists-check($palette, $tone) {
  }

  color: map-get(map-get($palettes, $palette), $tone);
}

@function c($palette, $tone: 500) {
  @if c-tone-exists-check($palette, $tone) {
  }

  @return map-get(map-get($palettes, $palette), $tone);
}

@mixin bg($palette, $tone: 500) {
  background-color: map-get(map-get($palettes, $palette), $tone);
}

@each $palette_key, $palette in $palettes {
  @each $name, $color in $palette {
    .c_#{unquote("#{$palette_key}-#{$name}")} {
      color: $color;
    }

    .c_#{unquote("#{$palette_key}-#{$name}")}__hover:hover {
      color: $color !important;
    }

    .bg_#{unquote("#{$palette_key}-#{$name}")} {
      background-color: $color;
    }

    .bg_#{unquote("#{$palette_key}-#{$name}")}__hover:hover {
      background-color: $color;
    }
  }
}
