.downloads
    &--preview
        text-align: center
        border: 1px solid #E0EBF3

        p
            +f_bold
            margin-top: 16px

    &--list
        list-style-type: none
        padding-left: unset

        li
            padding: 12px
            border-bottom: 1px solid c(grey, lines)

            &.spacer
                margin-bottom: 40px



            a
                > *
                    transition: .3s ease all

                svg
                    height: 16px
                    width: auto
                    transition: .3s ease all

                .downloads--list__label
                    margin-left: 16px


                &:hover
                    .downloads--list__label
                        color: c(primary, hover)
                        transition: .3s ease all

                    svg
                        transition: .3s ease all

                        path
                            fill: c(primary, hover)


    &-files
        .flex
            justify-content: space-between
            flex-direction: column





//  font-size: 24px
//  border-top: 1px solid c(primary, 500)
//
//  .download
//    height: 140px
//    border-bottom: 1px solid c(primary, 500)
//    display: flex
//    align-items:  center
//    justify-content: space-between
//
//    +mq($until: phablet)
//      height: 90px
//      display: block
//      position: relative
//      a
//        position: absolute
//        right: 0px
//        top: 0px
//        height: 40px
//        padding: 7px
//        svg
//          display: none
//
//    .dl1,
//    .dl2,
//    .dl3,
//    .dl4
//      width: 100px
//      padding: 22px
//      margin-right: 20px
//      svg
//        height: 45px
//        width: 45px
//        path
//          fill: c(white)
//
//      +mq($until: phablet)
//        margin-top: 10px
//        margin-bottom: 7px
//        padding: 5px
//        width: 65%
//
//        svg
//          height: 24px
//          width: 35px
//
//      &.dl1
//        background: #dedede
//      &.dl2
//        background: c(grey, m)
//      &.dl3
//        background: c(grey, d)
//      &.dl4
//        background: c(primary, 500)
//
//    .download-text
//      text-align: left
//      width: 100%
//
//
//
//  span.download-size
//    margin-left: 3px
//    margin-right: 3px
//    color: #B2B1AA
//    width: 70%
