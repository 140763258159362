.flex
  &--a-center
    img &
      display: flex
      align-items: center
      justify-content: center
      align-self: center



.flex
  display: flex !important
  +mq($until: tablet)
    flex-direction: column

  &--v-center
    align-items: center