$base-space: 1rem

.u-padtop
  @for $i from 1 through 10
    &--#{$i}
      padding-top: $i * $base-space

.u-padbot
  @for $i from 1 through 10
    &--#{$i}
      padding-bottom: $i * $base-space

.u-inset
  @for $i from 1 through 10
    &--#{$i}
      padding: $i * $base-space

.u-martop
  @for $i from 1 through 10
    &--#{$i}
      margin-top: $i * $base-space

.u-marbot
  @for $i from 1 through 10
    &--#{$i}
      margin-bottom: $i * $base-space

@mixin u-caps
  text-transform: uppercase
  letter-spacing: 0.2em


@mixin u-stretch($type: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0)
  position: $type
  top: $top
  right: $right
  bottom: $bottom
  left: $left

// simply enter font size and lineheight in px
@mixin u-font($fontsize: 16, $lineheight: 20)
  font-size: $fontsize + px
  font-size: $fontsize/ 16 + rem
  line-height: $lineheight / $fontsize + em


// $min-size: minimum element $property size in $unit specified
// $max-size: maximum element $property size in $unit specified
// $min-width: the screen width in $units where you want to lock in the $min-size
// $max-width: the screen width in $units where you want to lock in the $max-size
// $property: the property of the element that you would like to scale/lock
// $unit: the units which all measurements are taken in
// @mixin u-css-lock($min-size: 1, $max-size: 1.4, $min-width: 20, $max-width: 100, $property: font-size, $unit: px)
//   #{$property}: calc(#{$min-size}#{$unit} + (#{$max-size} - #{$min-size}) * ((100vw - #{$min-width}#{$unit}) / (#{$max-width} - #{$min-width})))

//   +mq($until: $min-width + 0px)
//     #{$property}: #{$min-size}#{$unit}

//   +mq($from: $max-width + 0px)
//     #{$property}: #{$max-size}#{$unit}


.u-nowrap
  white-space: nowrap

.u-big-target
  +u-stretch()
  display: block

.u-text-center
  text-align: center

.u-desktop
  +mq($until: tablet)
    display: none

.u-desktop-60
  +mq($from: tablet, $until: desktop)
    text-align: center
    img
      width: 60%
      margin: auto

.u-mobile-30
  +mq($until: phablet)
    text-align: center
    img
      width: 30%
      margin: auto

.u-padtop--10
  +mq($until: phablet)
    padding-top: 40px

.u-marbot--10
  +mq($until: phablet)
    margin-bottom: 40px


.u-mobile
  +mq($from: tablet)
    display: none

.u-padtop--5.home
  +mq($until: phablet)
    padding-top: 1rem


.u-bg--blue-dark
  background: #343b49
.u-bg--beige
  background: #acad9b
  h2, p, a, figcaption
    color: black !important
  a.button-tertiary
    border-color: black !important
.u-bg--blue
  background: #003768

.u-padtop--100percent
  +mq($from: tablet)
    padding-top: 100%

.u-marbot--2-tablet
  +mq($until: tablet)
    margin-bottom: 2rem
