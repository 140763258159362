.countup
  &__tiles
    display: flex
    grid-gap: 24px

    +mq($until: mobile)
      flex-direction: column

    +mq($until: desktop)
      flex-wrap: wrap

    &--tile
      //width: calc((100% - 72px) / 4)
      width: 100%

      +mq($from: phablet, $until: desktop)
        width: calc(100% / 2 - 12px)

  &__wrapper
    padding: 64px 0

    &--inner
      max-width: 70%
      margin: 0 auto

  h3
    +u-font(22, 32)
    +f_bold
    padding-bottom: 0
    margin-top: 0
    margin-bottom: 24px

  &__number
    +f_bold
    +u-font(75, 80)
    display: block
    margin-bottom: 5px
    text-align: right

    +mq($until: wide)
      +u-font(52, 65)

  &__unit
    display: block
    text-align: right
    margin-bottom: 0

  &.story09
    span.countup__unit,
    .countup__number
      display: inline-block

    span.countup__unit
      margin-left: 5px

    p.countup__unit
      text-align: left

    .countup__icon
      height: 110px
      max-height: 110px
