.slider
  // --swiper-pagination-bottom: -5%
  padding-bottom: 4rem

  .swiper-slide
    background-position: center
    background-size: cover
    position: relative

    &-row
      .col
        display: flex
        gap: 1rem
        flex-direction: row-reverse

        & > *
          flex-basis: 50%


    &-active
      .col
        opacity: 1
        transform: translateX(0px)


  .col
    padding: 100px 100px
    opacity: 0
    transform: translateX(-50px)
    transition: all .5s .5s ease-in-out


  figcaption
    +u-font(14, 22)
    color: c(white)
    text-align: right
    display: block

  figure
    img
      max-height: 300px
      object-fit: contain

  .img-small
    img
      max-height: 200px

  h2
    +u-font(36, 48)
    margin-bottom: 24px

  p
    margin-top: 0

.overlay
  position: relative
  &::after
   content: ""
   background: linear-gradient(90deg, rgb(black, 70%) 40%, rgba(black, 5%) 100%)
   width: 100%
   height: 100%
   position: absolute
   top: 0
   z-index: -1

.swiper-button-prev,
.swiper-button-next
  color: c(white)

.swiper-pagination .swiper-pagination-bullet
  background: c(primary)
