.intro
  &__home
    background: url("/assets/images/home/header__intro.jpg") no-repeat 35% top
    background-size: cover
    min-height: 500px
    display: flex
    justify-content: center
    flex-direction: column
    position: relative
    margin:
      left: .5rem
      right: .5rem

    +mq($from: tablet)
      margin:
        left: 1.5rem
        right: 1.5rem

    +mq($from: desktop)
      margin:
        left: 2rem
        right: 2rem

    &:before
      background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)
      opacity: .5
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      content: ''
      z-index: 0

  &__tiles
    &--tile
      margin-bottom: 3rem

    &__img
      overflow: hidden

      img
        width: 100%
        min-height: 300px
        object-fit: cover
        transition: .6s ease all

      &:hover
        img
          transform: scale(1.3)
          transition: .6s ease all

  h2
    font-size: 24px
    line-height: 30px
    margin-top: 32px
    margin-bottom: 18px

    +mq($until: tablet)
      margin-top: 1rem
      margin-bottom: .5rem

  p
    margin: 0
