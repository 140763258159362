.footer
  background-color: c(blue, bg)
  +section()

  &__row
    margin-bottom: 0
    padding:
      bottom: 32px
      top: 32px
    &--hidden
      display: none

  ul
    list-style: none
    display: flex
    flex-direction: row
    grid-gap: 32px
    padding: 0

    +mq($until: tablet)
      flex-direction: column

  a
    color: c(black)
    +u-font(16, 24)

  &__copyright
    margin: 0
    +u-font(16, 24)

//
//  &__row
//    +content-mq()
//    +col-mq()
//    padding: 35px 0
//
//    +mq($until: tablet)
//      +span(12)
//      margin-bottom: 10px
//
//    &--nav
//      +span(8)
//      +mq($until: tablet)
//        +span(12)
//
//        &::after
//          content: ''
//          background-color: c(white)
//          width: 100%
//          height: 1px
//          display: block
//          margin-bottom: 2rem
//          margin-top: 2rem
//
//    &--web
//      border-left: 1px solid c(white)
//      border-right: 1px solid c(white)
//      height: 144px
//
//      +span(8)
//      +mq($until: tablet)
//        +span(12)
//        border: none
//        height: 135px
//
//        &::after
//          content: ''
//          background-color: c(white)
//          width: 100%
//          height: 1px
//          display: block
//          margin-bottom: 2rem
//          margin-top: 2rem
//
//
//    &--social
//      +span(8)
//      +mq($until: tablet)
//        +span(12)
//
//    .content
//
//  ul
//    list-style-type: none
//
//    li
//      display: block
//      line-height: 2em
//
//  a, p
//    color: c(white)
//    +u-font(16)
//    line-height: 2.4em
//    margin-bottom: 0
//    transition: all .2s
//
//  a:hover
//    color: c(primary, 500) !important
//
//  &--last
//    background: none
//
//    .footer__inner--last
//      padding-top: 0.5rem
//      padding-bottom: 0.5rem
//
//      p
//        color: c(black)
