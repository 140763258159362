:root
 --dd-keyfigures-color-primary: #00569D
 --dd-keyfigures-color-neutral: #000

 --dd-keyfigures-font-family-standard: "Frutiger-Reg", sans-serif

 --dd-keyfigures-font-size: 14px
 --dd-keyfigures-font-size--sm: 14px
 --dd-keyfigures-font-size--xs: 12px

 --dd-keyfigures-table-highlight-bg-color: transparent
 --dd-keyfigures-table-highlight-border-color: var(--dd-keyfigures-color-primary)
 --dd-keyfigures-table-head-highlight-bg-color: transparent
 --dd-keyfigures-table-head-highlight-border-color: var(--dd-keyfigures-color-primary)
