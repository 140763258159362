.nav__tile
  border: 2px solid c(blue, lines)
  display: flex
  //justify-content: center
  flex-direction: row
  align-items: center
  transition: .3s ease all

  &--img
    max-width: 120px
    width: calc((100% - 32px) / 3)

    +mq($until: tablet)
      // display: none
      width: calc((100% - 32px) / 2)
      max-width: 50px


  &--title
    +u-font(18, 28)
    width: calc((100% - 32px) / 3 * 2)
    padding: 18px
    transition: .3s ease all

    +mq($until: tablet)
      padding: 10px
      width: unset

  &--prev
    +mq($from: tablet)
      flex-direction: row-reverse

    .nav__tile--title
        text-align: right


  &:hover
    background: c(blue, lines)

    .nav__tile--title
      color: c(black)
      transition: .3s ease all


.nav__tiles
  grid-gap: 32px 48px
  display: grid !important
  grid-template-columns: 1fr 1fr 1fr

  +mq($until: tablet)
    grid-gap: 5px
    grid-template-columns: auto

  &--pagination
    padding: 65px 0

    &__label
      +u-font(14, 18)
      text-transform: uppercase
      display: inline-block
      margin-bottom: 8px

    +mq($until: desktop)
      .nav__tile
        margin-bottom: 2rem

    .content--right
      +mq($until: tablet)
        text-align: revert
