.pillars
  &__wrapper
    border: 2px solid c(black)
    padding: 48px 60px

  &__inner
    display: grid
    grid-template-columns: auto auto
    grid-gap: 32px 48px

    +mq($until: tablet)
      grid-template-columns: auto

  &__title
    +u-font(22, 30)
    text-transform: uppercase

  &__item

    &--label
      > *
        +u-font(18, 28)
        +f_bold
        color: c(black)
        display: inline-block
        border: 2px solid c(black)
        padding: 10px 10px 8px
        //margin-top: 48px
        transition: .3s ease all

      &.white > *
        color: c(white)
        border-color: c(white)

      &--unbordered > *
        border: 0
        padding: 0

    a
      &:hover
        color: c(primary)
        border-color: c(primary)
        cursor: pointer


    &--text
      margin-top: 18px
      margin-bottom: 0

    &--molecule
      //width: calc((100% - 24px) / 4)
      max-width: 120px
      width: 100%
      object-fit: contain
      margin-right: 24px
      height: 100%
