.magazin-teaser
  &__column
    display: block

    .magazin-teaser__grid--wrapper
      padding-bottom: 4rem

      &:last-of-type
        padding-bottom: 0

  +mq($from: tablet)
    &__column
      display: grid !important
      gap: 4rem

      &-wrapper
        display: flex !important
        justify-content: space-evenly

  &__big
    +mq($from: tablet)
      margin-bottom: 150px

  &__wrapper
    background-image: url("/assets/images/home/interview.jpg")
    background-repeat: no-repeat
    background-size: 100%
    position: relative
    padding-top: 60%

    +mq($from: desktop)
      padding-top: 49%

    &:after
      content: ''
      width: 100%
      height: 70%
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)
      position: absolute
      bottom: 0
      z-index: 0

      +mq($until: desktop)
        display: none

  &__inner
    display: flex !important
    justify-content: flex-end
    flex-direction: column
    //max-width: 60%
    padding-bottom: 60px
    padding-left: 0 !important

    +mq($from: desktop)
      height: 0

    +mq($from: wide)
      max-width: 60%

    & *
      z-index: 1

    +mq($until: desktop)
      .button-tertiary
        border-color: c(black)
        color: c(black)

    h3
      color: c(white)
      margin: 0 0 18px 0
      padding: 0

      +mq($until: desktop)
        color: c(black)

    p
      margin: 0 0 32px 0
      color: c(white)

      +mq($until: desktop)
        color: c(black)

  &__grid
    .content
      display: flex

      +mq($until: tablet)
        flex-wrap: wrap
        gap: 3rem

      &:not(:last-child)
        margin-bottom: 32px

      &--img
        overflow: hidden
        margin-bottom: 32px

        img
          width: 100%
          transition: 0.3s ease all
          object-fit: cover

          &:hover
            transform: scale(1.5)
            transition: 0.3s ease all

      &--text
        h3
          +u-font(24, 30)
          margin: 0 0 18px 0
          padding: 0

        p
          margin: 0 0 32px

    h3
      color: c(black)
