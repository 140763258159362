@import grid-basic
@import grid-mq

$columns: 12

// basic settings
$spacing-small: 1rem
$spacing-medium: 1.5rem
$spacing-large: 2rem
//
// $gutter-small: 1rem
// $gutter-medium: 3rem
// $gutter-large: 4rem

//$debug: true

// GRID SYSTEM – CSS CLASSES //
// DOM: section > content > col (defining span)

// custom grid classes //
.section
  +section-mq()

  &.section--no-spacing
    margin-bottom: 0

  &.section--primary
    +c(white)
    +bg(primary)

  .content
    +content-mq()
    width: 100%

    +mq($until: tablet)
      padding: 0px 100px !important

    +mq($until: phablet)
      padding: 0px 30px !important

    &.content--no-padding
      padding: 0px 0 !important
    &.content--full
      max-width: 100%
    &.content--no-gutter
      padding-left: 0
      padding-right: 0
    &--center
      text-align: center
    &--right
      text-align: right

    .col
      +col-mq()

      &.col--no-gutter
        padding-left: 0
        padding-right: 0

      // If you use nested structures use:
      // .section > .content > .col.col--nested.content > .content > .col.col--nested-content > content > …
      &.col--nested-content
        padding-left: 0
        padding-right: 0
        & > .content
          padding-left: 0
          padding-right: 0

      // CUSTOM COLUMNS //
      &--12
        +span(12)

      &--11
        +span(12)
        +mq($from: tablet)
          +span(11)

      &--10
        +span(12)
        +mq($from: tablet)
          +span(10)

      &--9
        +span(12)
        +mq($from: tablet)
          +span(9)

      &--8
        +span(12)
        +mq($from: tablet)
          +span(8)

      &--7
        +span(12)
        +mq($from: tablet)
          +span(7)

      &--6
        +span(12)
        +mq($from: tablet)
          +span(6)

      &--5
        +span(12)
        +mq($from: tablet)
          +span(5)

      &--4
        +span(12)
        +mq($from: tablet)
          +span(4)

      &--3
        +span(12)
        +mq($from: tablet)
          +span(3)

      &--2
        +span(12)
        +mq($from: tablet)
          +span(2)


      @for $i from 1 through $columns
        &--lead--#{$i}
          +lead(0)
          +mq($from: tablet)
            +lead($i)

      @for $i from 1 through $columns
        &--trail--#{$i}
          +trail(0)
          +mq($from: tablet)
            +trail($i)
