.magazin

  p.intro
    +u-font(24, 36)

    +mq($until: tablet)
      +u-font(20, 28)

  h1
    &.story
      +u-font(75, 80)

      +mq($until: tablet)
        +u-font(59, 65)

  &.story05
    .col--bg-blue
      background: c(stories, blue)
      padding-top: 64px

      h3, p
        color: c(stories, green)
        padding: 0 32px
        margin-top: 0

    .col--bg-green
      background: c(stories, green)
      padding-top: 64px

      h3, p
        color: c(stories, blue)
        padding: 0 32px
        margin-top: 0

  &.story07
    .scrollsnap
      flex-flow: column nowrap
      overflow: auto
      display: flex
      flex: none
      scrollbar-width: none

      +mq($from: desktop)
        height: calc(100vh - 165px)
        scroll-snap-type: y mandatory

      .section
        scroll-snap-align: center
        flex: none
      //height: calc(100vh - 165px)


      .content
        //height: calc(100vh - 165px)

        +mq($from: desktop)
          height: calc(100vh - 165px)
          min-height: 520px

      .col.flex
        justify-content: center
        flex-direction: column
        position: relative
        //height: 100%

        &:not(.image)
          padding: 95px

          +mq($until: desktop)
            padding: 2rem

          & > *
            width: 80%
            margin-left: auto
            margin-right: auto

        &.arrow
          &:after
            content: ''
            position: absolute
            top: calc(50% - 80px)
            width: 0
            height: 0
            clear: both
            z-index: 99
            border-top: 80px solid transparent !important
            border-bottom: 80px solid transparent !important

          &--to-left
            &:after
              left: -80px
              border-right: 80px solid

              +mq($until: desktop)
                left: -50px

          &--to-right
            &:after
              right: -80px
              border-left: 80px solid

              +mq($until: desktop)
                right: -50px

          &--orange
            &:after
              border-color: c(secondary)

          &--blue
            &:after
              border-color: c(primary, 500)

          &--green
            &:after
              border-color: c(stories, green)


        &.image
          height: auto
          background-size: cover
          background-position: center

          img
            height: 100%
            object-fit: cover
            width: 100%


      h1
        +u-font(84, 80)
        color: c(stories, green)

        +mq($until: desktop)
          +u-font(60, 66)

      h2
        width: 100%

      p
        color: inherit

  &.story09
    .sdg--box
      //background: #EBEBEB
      border: 2px solid black

      &--right
        border-right: 0

      &--left
        border-left: 0

      padding:
        top: 32px !important
        bottom: 48px !important

      .flex
        justify-content: end
        margin-bottom: 2rem

      &__icon
        display: flex
        gap: 20px
        justify-content: flex-end
        flex-wrap: wrap
        margin-right: 20px

        img
          height: 55px
          width: 55px


      &__nr
        margin-right: 32px

        > span
          +u-font(100, 110)

      &__text
        width: 60%

        +mq($until: phablet)
          width: 100%

        > span
          +u-font(24, 36)

  &.story08
    .rounded-borderd
      img
        border-radius: 100%
        border: 2px solid c(black)
