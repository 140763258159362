.lightbox
  --headerHeight: 150px
  display: none
  position: fixed
  top: var(--headerHeight)
  right: 0
  bottom: 0
  left: 0
  z-index: 100
  background-color: white

  &__content
    display: flex!important
    flex-direction: column
    align-items: flex-end

  &.active
    display: flex

  .icon--close
    width: 40px
    height: 40px
    justify-self: flex-end
    cursor: pointer
    padding: 1rem
