body
  --headerHeight: 165px
  font-size: 100%
  +f_regular
  text-size-adjust: none
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-rendering: optimizeLegibility
  min-width: 20rem
  margin: 0

a
  color: c(primary)
  transition: 0.3s ease all
  cursor: pointer


  svg
    path
      transition: 0.3s ease all

  &:hover
    color: c(primary, hover)

    //svg
    //  path
    //    transition: 1s ease all

a.white
    color: c(white)

    &:hover
        opacity: .7

img
  width: 100%

.dev-bookmark
    border: 1px solid rgba(255, 0, 0, 0.55)
    position: relative

    &:after
        content: " ! "
        width: 100%
        height: 100%
        color: red
        display: inline-block
        left: -20px
        position: absolute
        top: 0
        font-size: 25px
        font-weight: bold

.dev-placeholder
    position: relative

    &:after
        content: "PLACEHOLDER"
        width: fit-content
        padding: 10px
        height: 30px
        position: absolute
        background: red
        top: 0
        right: 0
        font-size: 20px
        font-weight: bold
        color: white
        display: inline-flex
        align-items: center

@mixin box-shadow
  box-shadow: 0px 6px 20px rgba(black, .16)

//reset figure
figure
  margin-block-start: 0
  margin-block-end: 0
  margin-inline-start: 0
  margin-inline-end: 0


.printlink
  float: right
  display: inline-block
  height: 50px
  width: 50px
  padding: 3px


@media print
  .header,
  .footer,
  .footer__row,
  .nav__tiles--pagination,
  #content-top,
  #usercentrics-root
    display: none
    height: 0

  .content__wrapper > .section:first-child
    margin-top: 0!important
    padding-top: 0!important
