.button
  +f_bold
  +u-font(18, 24)
  display: inline-block
  border: 1px solid
  padding: 15px 25px
  text-align: center
  color: c(white)
  transition: all 0.2s
  position: relative
  width: fit-content

  &:hover
    color: c(white)

  &-primary
    background: c(primary, 500)
    border-color: c(primary, 500)

    &:hover
      background: c(primary, hover)
      border-color: c(primary, hover)

  &-secondary
    background: unset
    border-color: c(primary, 500)
    color: c(primary, 500)

    &:hover
      background: c(primary, hover)
      border-color: c(primary, hover)

  &-tertiary
    background: unset
    border-color: c(white)
    color: c(white)

    &:hover
      background: c(white)
      border-color: c(white)
      color: c(black)

  &-highlight
    background: c(secondary, 500)
    border-color: c(secondary, 500)

    &:hover
      background: c(secondary, hover)
      border-color: c(secondary, hover)


  &--right
    float: right
    margin-top: 15px

  &--download
    height: 55px
    svg.icon
      width: 10px
      height: 10px
      float: left
      color: black


  &--home
    background: rgba(0,0,0,0.8)
    color: c(white)
    border: 2px solid c(white)
    padding: 10px 30px
    margin: 10px
    cursor: pointer
    letter-spacing: 0.025em

    &:hover
      background: c(white)
      color: c(black)
      border: 2px solid c(black)