.reveal
  opacity: 0
  transition: all 1s ease-in-out
  transform: translate(0px, 30px)

  &.is-active
    opacity: 1
    transform: translate(0px)

  &--image
    overflow: hidden
    position: relative
    height: auto

    img
      position: relative
      right: -800px
      height: auto
      transition: all 0.8s ease-in-out

    &.is-active
      img
        right: 0px
