.dd-keyfigures
    &__chart
        mix-blend-mode: multiply

    &__accordion-toggle
      +f_bold
      +u-font(20, 25)
      line-height: 1.5
      padding: 1em 0
      cursor: pointer

    .dd-keyfigures__section
      border: 1px solid c(blue, light)
      padding-inline: 1rem

    &__all-years-toggle
      +f_regular

    &__controls-label,
    &__figures-label
        display: none

    &:first-child
        padding-left: 5px

    &__icon
        width: 15px

    &__button
        min-width: unset

        &-icon
            border-radius: unset
            border-width: 1px
            width: 30px
            height: 30px

    &-label
        display: none

    &__legend li p,
    &__toggle-off-text,
    &__toggle-on-text
        font-size: 14px

    &__control-strip--bottom
        justify-content: center

    .dd-keyfigures__data-table
        @extend %table

    &__footnotes
        +mq($from: tablet)
            margin-left: 8rem

    &__data-table
        padding-bottom: 20px !important

        thead
            th
                padding-top: 3px
                text-align: right !important
                border-bottom: 0

                &:first-child
                    text-align: left !important

                    #profit &, #balance, #ergebnis &
                        &::before
                            html[lang=de] &
                                content: 'in Mio. €'

                            html[lang=en] &
                                content: '€ millions'

        tbody
            td,
            th
                font-family: var(--dd-keyfigures-font-family-standard)
                border-bottom: 0

    &__table-head-cell-highlight
        border-width: 2px 2px 0px 2px !important
        padding: 12px 8px 8px 8px
        border-style: solid

        &::after
            content: ''
            position: absolute
            bottom: 0
            left: -2px
            right: -2px
            height: 20px
            //transform: translateY(20px)
            border: 1px solid var(--dd-keyfigures-color-primary)
            border-width: 0 0 1px 0
            background: unset !important

    &__table-cell-highlight
        border-width: 0 2px !important
        border-style: solid
        padding: 12px 8px 8px 8px
        font-weight: bold !important
        color: c(primary) !important

        :last-child > &
            position: relative

        &::after
            content: ''
            position: absolute
            bottom: 0
            left: -2px
            right: -2px
            height: 20px
            //transform: translateY(20px)
            border: 1px solid var(--dd-keyfigures-color-primary)
            border-width: 0 0 1px 0
            background: unset !important


        &:last-child
            &::after
                content: ''
                position: absolute
                bottom: 0
                left: -2px
                right: -2px
                height: 20px
                //transform: translateY(20px)
                border: 1px solid var(--dd-keyfigures-color-primary)
                border-width: 0 0 1px 0


    &__table-head-cell-unit,
    &__table-cell-unit
        display: none

    [data-active-view="table"]
        .dd-keyfigures__legend,
        .dd-keyfigures__control-strip--bottom
            display: none

        .dd-keyfigures__chart
            height: 0

    tr
        &:last-of-type
            .dd-keyfigures__table-cell-number.dd-keyfigures__table-cell-highlight:after
                background: c(primary) !important
                width: 100%
                left: 0
