.quote
 &__content
  +u-font(32, 44)
  text-transform: uppercase
  letter-spacing: .8px
  margin: 0 0 18px 0

 &__name
  +u-font(16, 19)
  color: c(gray, 500)
  margin: 0