.countup
  img
    width: auto
    max-height: 85px
//  text-align: center
//  h1
//    +f-bold
//
//  &__meta
//    font-size: 0.95rem
//    // line-height: 18px
//    +f-bold
//    margin-bottom: 30px
//    margin-top: 1rem
//
//  &__title
//    font-size: 28px
//    // line-height: 34px
//    margin-bottom: 80px
//    +f-bold
//    letter-spacing: 0.015em
//
//  &__composition
//    display: inline-block
//    width: 260px
//    &::before
//      content: ''
//      display: block
//      margin: auto
//      background-color: c(primary, 500)
//      width: 240px
//      height: 1px
//      margin-bottom: 1rem
//    &::after
//      content: ''
//      display: block
//      margin: auto
//      background-color: c(primary, 500)
//      width: 240px
//      height: 1px
//      margin-bottom: 1rem
//      +mq($until: phablet)
//        display: none
//    &:last-child
//      &::after
//        +mq($until: phablet)
//          display: block
//
//
//  &__number
//    +f-bold
//    +u-font(62, 65)
//    letter-spacing: 0px
//    // margin-bottom: 30px
//    // margin-top: 30px
//    +mq($until: desktop)
//      +u-font(35, 38)
//
//    &--finance.countup__number
//      margin-top: 30px
//      font-size: 90px
//      letter-spacing: 0px
//
//  &__unit
//    +f-bold
//    +u-font(62, 65)
//    letter-spacing: 0px
//    // margin-bottom: 30px
//    // margin-top: 30px
//    margin-left: .5ch
//    +mq($until: desktop)
//      +u-font(35, 38)
//
//  &__text
//    +f-bold
//    +u-font(62, 65)
//    letter-spacing: 0px
//    +mq($until: desktop)
//      +u-font(35, 38)
//
//    &--finance.countup__text
//      +f-regular
//      font-size: 20px
//      line-height: 34px
//      margin-bottom: 30px
//
//
//  &__image
//    width: 150px
//    margin: auto
//
//
//
//.is-active
//  .countup__image
//    svg
//      #diagram
//        polygon
//          transform: translateY(170px)
//          animation: countup_image 1.5s forwards
//          &:first-child
//            animation-delay: .3s
//          &:nth-child(2)
//            animation-delay: .6s
//          &:nth-child(3)
//            animation-delay: .9s
//          &:nth-child(4)
//            animation-delay: 1.2s
//          &:nth-child(5)
//            animation-delay: 1.5s
//
//
//    @keyframes countup_image
//      0%
//        transform: translateY(170px)
//      100%
//        transform: translateY(0px)