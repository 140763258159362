@mixin text-caps
  text-transform: uppercase
  letter-spacing: 0.2em

// h1, .h1, %h1,
// h2, .h2, %h2,
// h3, .h3, %h3,
// h4, .h4, %h4
//   padding-bottom: .5em

h1, .h1, %h1
  +f_regular
  +u-font(42, 50)
  margin: 0
  position: relative

  &.home
    color: c(white)
    margin-bottom: 40px

  +mq($until: desktop)
    +u-font(36, 48)

  +mq($until: phablet)
    +u-font(32, 42)

h2, .h2, %h2
  +f_bold
  +u-font(36, 48)
  color: c(black,500)
  margin: 0px
  // text-decoration: underline

  +mq($until: desktop)
    +u-font(32, 42)

  +mq($until: phablet)
    +u-font(26, 34)

  &.big
    +u-font(42, 50)
    //padding-bottom: 25px

  &.small
    +u-font(24, 32)

h3, .h3, %h3
  +u-font(24, 32)

  +mq($until: desktop)
    +u-font(22, 28)

  +mq($until: phablet)
    +u-font(22, 28)

  &.big
    +u-font(26, 34)


h4, .h4, %h4
  +u-font(20, 24)
  color: c(black)
  +f_regular

  +mq($until: desktop)
    +u-font(16, 22)

  +mq($until: phablet)
    +u-font(16, 22)

a
  text-decoration: none
  color: c(primary)

a, p, li, span
  +f_regular
  +u-font(18, 25)
  line-height: 1.5


  +mq($until: desktop)
    +u-font(18, 24)

  +mq($until: phablet)
    +u-font(16, 22)

p
  color: c(black)
  //margin-bottom: 30px
  //+u-font(20px, 28px)

  &.intro
    +u-font(24, 36)
    margin: 0

    +mq($until: desktop)
      +u-font(22, 28)

    +mq($until: tablet)
      +u-font(20, 26)

    +mq($until: phablet)
      +u-font(20, 26)

blockquote
  +u-font(24, 34)
  +f_bold
  +mq($until: desktop)
    +u-font(16, 22)

small, .small
  +u-font(15, 21)
  line-height: 1.4em


.medium
  +u-font(17,20)
  +mq($until: desktop)
    +u-font(15, 17)
  +mq($until: tablet)
    line-height: 1.53333em

.large
  +u-font(45,60)
  +mq($until: desktop)
    +u-font(35, 40)
  +mq($until: tablet)
    line-height: 1.53333em

figure
  +mq($until: phablet)
    img
      padding-bottom: 20px

figcaption
  font-size: 0.95rem
  +f_bold
  letter-spacing: 0.030em
  color: c(black, 500)
  margin-top: 1rem

  +mq($until: desktop)
    +u-font(15, 17)

  +mq($until: tablet)
    margin-bottom: 2rem

  +mq($until: phablet)
    margin-bottom: 1rem

  small
    color: c(black)

    +mq($until: desktop)
      +u-font(15, 17)

ul.dashed
  list-style-type: none
  margin-left: 1.5rem
  margin-bottom: 1rem
  padding-left: 1em
  li
    margin-bottom: 1em
    &:last-child
      margin-bottom: 0
    &::before
      display: inline-block
      content: "–"
      width: 1em
      margin-left: -1em

hr
  //display: block
  //height: 2px
  //width: 100%
  //border: none
  //background-color: c(grey,d)
  //margin:
  //  top: 2rem
  //  bottom: 2rem
  //  left: auto
  //  right: auto
  //
  //.section--primary &
  //  background-color: c(white)

.t-hero
  +u-font(70, 75)
  margin-bottom: 1em

  +mq($until: phablet)
    +u-font(45, 50)
    +f_bold

.t-ultra
  +u-font(200, 210)

  +mq($until: wide)
    +u-font(180, 190)

  +mq($until: desktop)
    +u-font(120, 150)

.t-center
  text-align: center

.t-right
  text-align: right

.t-left
  text-align: left


.t-bottom
  vertical-align: bottom !important

.no-addendum
  display: none


.number
  //+u-font(160,120)
  //+f_bold
  //color: c(primary,500)
  //
  //&.small
  //  +u-font(88,100)
  //  +mq($until: phablet)
  //    +u-font(40,100)
  //
  //
  //&:before
  //  content: ''
  //  width: 2px
  //  height: 75px
  //  background: c(black, 500)
  //  display: block
  //  margin: auto
  //  margin-bottom: 50px
  //
  //&__caption
  //  padding-top: 22px
  //  text-transform: uppercase
  //  +u-font(24,32)
  //  +f_regular
  //  color: c(grey, d)


.vertical
  position: relative

  &__content
    position: absolute

    p
      margin-bottom: -10px

      +mq($until: desktop)
        margin-bottom: 0px


    +mq($until: wide)
      position: relative

    &--left
      transform: rotate(270deg)
      left: -280px
      padding-top: 20%

      +mq($until: desktop)
        margin-bottom: 30px

    &--right
      right: -150px
      transform: rotate(90deg)

    &--right,
    &--left
      +mq($until: wide)
        transform: none
        left: 0px
        padding: 20px 0px

.text
  &-white
    color: c(white)

.uppercase
  text-transform: uppercase

.fw-reg
    +f_regular