@font-face
  font-family: "Frutiger-Reg"
  src: url("assets/fonts/FrutigerLTW04-55Roman.eot") format("eot"), url("assets/fonts/FrutigerLTW04-55Roman.woff") format("woff"), url("assets/fonts/FrutigerLTW04-55Roman.ttf") format("truetype")

@font-face
  font-family: "Frutiger-Bd"
  src: url("assets/fonts/FrutigerLTW04-65Bold.eot") format("eot"), url("assets/fonts/FrutigerLTW04-65Bold.woff") format("woff"), url("assets/fonts/FrutigerLTW04-65Bold.ttf") format("truetype")


%f_regular
  font-family: Frutiger-Reg

@mixin f_regular
  @extend %f_regular

%f_bold
  font-family: Frutiger-Bd
  font-weight: normal

@mixin f_bold
  font-family: Frutiger-Bd
  font-weight: normal
  // @extend %f_bold

.center
  text-align: center

.bold
  @extend %f_bold

.underlined
  text-decoration: underline

strong
  @extend %f_bold

h1
  font-size: 62px
  font-family: Frutiger-Bd
  font-weight: normal