.image
  height: 100%

  &__sticky
    display: inline-block
    vertical-align: top
    position: sticky
    top: var(--headerHeight)

    +mq($until: tablet)
      position: static

  &__signature
    max-width: 300px

  &__sub
    margin-top: 1rem
    width: 100%
    text-align: left
    +u-font(16, 19)
    color: c(gray, 500)
    display: block




  &.pad--right-20
    .image__sub
      padding-right: 20%


  &--landscape
    aspect-ratio: 1.5
    object-fit: cover

  &--portrait
    aspect-ratio: .75
    object-fit: cover

img
  &.stretched
    height: 100%
    object-fit: cover

.svg
  &__visible
    svg
      overflow: visible
